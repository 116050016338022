<template>
  <div>
    <week-agenda
      v-if="$store.state.calendarType === 1"
    />
    <monthly-calendar
      v-if="$store.state.calendarType === 2"
    />
    <search-jobs
      v-if="$store.state.calendarType === 3"
    />
    <selected-job
      v-if="$store.state.selectedJob > 0"
    />
    <planned-job
      v-if="$store.state.selectedPlannedJob > 0"
    />
    <edit-maintenance
      v-if="$store.state.selectedVehicleMaintenanceId > 0"
    />
    <view-note
      v-if="$store.state.selectedNoteId > 0"
    />
    <view-holiday
      v-if="$store.state.selectedWorkerUnavailableId > 0"
    />
    <view-survey
      v-if="$store.state.selectedSurveyId > 0"
    />
  </div>
</template>

<script>
import WeekAgenda from '@/components/PlanJobs/WeekAgenda.vue';
import MonthlyCalendar from '@/components/PlanJobs/MonthlyCalendar.vue';
import SearchJobs from '@/components/PlanJobs/SearchJobs.vue';
import SelectedJob from '@/components/Jobs/SelectedJob/SelectedJob.vue';
import PlannedJob from '@/components/Jobs/SelectedPlannedJob/SelectedPlannedJob.vue';
import EditMaintenance from '@/components/Vehicles/EditMaintenanceForCalendar.vue';
import ViewNote from '@/components/Tools/ViewNote.vue';
import ViewHoliday from '@/components/Tools/ViewHoliday.vue';
import ViewSurvey from '@/components/Tools/ViewSurvey.vue';
import axios from '../../axios';

export default {
  name: 'Calendar',
  components: {
    SelectedJob,
    PlannedJob,
    EditMaintenance,
    WeekAgenda,
    MonthlyCalendar,
    SearchJobs,
    ViewNote,
    ViewHoliday,
    ViewSurvey,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    setUp() {
      if (this.token.length > 0) {
        axios.get(`/setup/load.json?token=${this.token}`)
          .then((response) => {
            this.$store.commit('setUp', response.data);
          });
      }
    },
  },
  mounted() {
    this.setUp();
  },
};
</script>

<style lang="scss">
  .main-calendar {
    .v-calendar-daily_head-weekday,
    .v-calendar-daily_head-day-label {
      display: none;
    }
  }
  .v-calendar-daily {
    height: auto!important;
  }
</style>
